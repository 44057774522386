/*External dependencies */
import update from 'immutability-helper';

/*Local dependencies*/
import { AuthActionTypes, AuthActions } from './action';
import { Errors } from './errors';

interface passwordValidatesType {
  isNotEmpty: boolean;
  isLong: boolean;
  isAlphanumeric: boolean;
  isSimvol: boolean;
  counter: number;
}

export interface AuthStateType {
  user: boolean;
  isModalOpen: boolean;
  id: string;
  modalState: number;
  email: string;
  pincode: number | string;
  emailError: string | null;
  error: any;
  isSignUp: boolean;
  signInResponse: any;
  session: any;
  isLoading: boolean;
  password: string;
  repeatPassword: string;
  passwordValidates: passwordValidatesType;
}

export const initialAuthState: AuthStateType = {
  user: false,
  isModalOpen: false,
  modalState: 0,
  email: '',
  password: '',
  repeatPassword: '',
  emailError: null,
  error: null,
  pincode: '',
  isSignUp: true,
  signInResponse: null,
  session: null,
  isLoading: false,
  id: '',
  passwordValidates: {
    isNotEmpty: false,
    isLong: false,
    isAlphanumeric: false,
    isSimvol: false,
    counter: 0,
  },
};

export const validRegexEmail = /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-z0-9-]+\.[a-z]+$/;

export function AuthReducer( state = initialAuthState, action: AuthActions ): AuthStateType {
  let payload = 'payload' in action ? action.payload : null;


  switch ( action.type ) {
    case AuthActionTypes.TOGGLE_MODAL_STATE:
      return update( state, {
        isModalOpen: { $set: !state.isModalOpen },
        emailError: { $set: null }
      } );

    case AuthActionTypes.TOGGLE_SIGN_UP_STATE:
      return update( state, {
        isSignUp: { $set: !state.isSignUp },
      } );

    case AuthActionTypes.INCREMENT_WINDOW_STATE:
      return update( state, {
        error: { $set: false },
        pincode: { $set: '' },
        emailError: { $set: null },
        modalState: { $set: state.modalState + 1 },
        password: { $set: '' },
        passwordValidates: { $set: initialAuthState.passwordValidates }
      } );

    case AuthActionTypes.DECREMENT_WINDOW_STATE:
      return update( state, {
        email: { $set: state.email },
        pincode: { $set: '' },
        emailError: { $set: null },
        modalState: { $set: state.modalState - 1 },
        password: { $set: '' },
        passwordValidates: { $set: initialAuthState.passwordValidates }
      } );

    case AuthActionTypes.INPUT_FIELD_EMPTY:
      return update( state, {
        email: { $set: '' },
      } );

    case AuthActionTypes.ON_CHANGE_MAIL_INPUT:
      if ( payload.match( validRegexEmail ) || initialAuthState.email ) {
        return update( state, {
          email: { $set: payload },
          emailError: { $set: null },
        } );
      } else {
        return update( state, {
          email: { $set: payload },
          emailError: { $set: Errors.emailIncorrect },
        } );
      }

    case AuthActionTypes.ON_CHANGE_PASSWORD_INPUT:
      const isNotEmpty = payload.length > 0;
      const isLong = payload.length > 7;
      const isAlphanumeric = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[A-Z]).+$/.test( payload );
      const isSimvol = /[~#@$%&!*_?^-]/.test( payload );
      const counter = [isNotEmpty, isLong, isAlphanumeric, isSimvol].filter( Boolean ).length;

      return update( state, {
        password: { $set: payload },
        passwordValidates: {
          isNotEmpty: { $set: isNotEmpty },
          isLong: { $set: isLong },
          isAlphanumeric: { $set: isAlphanumeric },
          isSimvol: { $set: isSimvol },
          counter: { $set: counter },
        },
      } );

    case AuthActionTypes.ON_CHANGE_REPEAT_PASSWORD_INPUT:
      return update( state, {
        repeatPassword: { $set: payload }
      } )

    case AuthActionTypes.ON_CHANGE_PINCODE_INPUT:
      return update( state, {
        pincode: { $set: payload },
      } );

    case AuthActionTypes.SIGN_UP:
      return update( state, {
        isLoading: { $set: true },
      } );

    case AuthActionTypes.SIGN_UP_SUCCEEDED:
      return update( state, {
        modalState: { $set: state.modalState + 1 },
        isLoading: { $set: false },
        emailError: { $set: null }
      } );

    case AuthActionTypes.SIGN_UP_FAILED:
      return update( state, {
        isLoading: { $set: false },
        emailError: { $set: Errors.hasAnAccount }
      } );

    case AuthActionTypes.CONFIRM_PINCODE:
      return update( state, {
        isLoading: { $set: true },
      } );

    case AuthActionTypes.CONFIRM_PINCODE_SUCCEEDED:
      return update( state, {
        modalState: { $set: 1 },
        isSignUp: { $set: false },
        isLoading: { $set: false },
        emailError: { $set: null }
      } );

    case AuthActionTypes.CONFIRM_PINCODE_FAILED:
      return update( state, {
        isLoading: { $set: false },
        emailError: { $set: Errors.pincodeIncorrect }
      } );

    case AuthActionTypes.SIGN_IN:
      return update( state, {
        isLoading: { $set: true },
      } );

    case AuthActionTypes.SIGN_IN_SUCCEEDED:
      const { result } = action;

      return update( state, {
        isModalOpen: { $set: false },
        isLoading: { $set: false },
        user: { $set: true },
        signInResponse: { $set: result },
      } );

    case AuthActionTypes.SIGN_IN_FAILED:
      return update( state, {
        isLoading: { $set: false },
        emailError: { $set: Errors.signInError }
      } );

    case AuthActionTypes.RESET_PASSWORD_REQUEST:
      return update( state, {
        isLoading: { $set: true },
      } )

    case AuthActionTypes.RESET_PASSWORD_SUCCEEDED:
      return update( state, {
        isLoading: { $set: false },
        modalState: { $set: 1 },
        emailError: { $set: null }
      } )

    case AuthActionTypes.RESET_PASSWORD_FAILED:
      return update( state, {
        isLoading: { $set: false },
        emailError: { $set: Errors.resetPasswordError }
      } )

    case AuthActionTypes.FORGOT_PASSWORD_BUTTON:
      return update( state, {
        modalState: { $set: 2 }
      } )

    case AuthActionTypes.FORGOT_PASSWORD:
      return update( state, {
        isLoading: { $set: true },
      } )

    case AuthActionTypes.FORGOT_SUCCEEDED:
      return update( state, {
        isLoading: { $set: false },
        modalState: { $set: 3 }
      } )

    case AuthActionTypes.FORGOT_FAILED:
      return update( state, {
        isLoading: { $set: false },
        emailError: { $set: Errors.forgotError }
      } )

    case AuthActionTypes.SIGN_OUT_SUCCEEDED:
      return initialAuthState

    case AuthActionTypes.INITIAL_CLIENT_REQUEST:
      return update( state, {
        user: { $set: false },
        isLoading: { $set: true }
      } )
    case AuthActionTypes.INITIAL_CLIENT_SUCCEEDED:
      const { session } = action
      const emailAttribute = session[2]
      const emailValue = emailAttribute ? emailAttribute.Value : null;
      const valueAttribute = session[0]
      const value = valueAttribute ? valueAttribute.Value : null

      return update( state, {
        session: { $set: session },
        email: { $set: emailValue },
        user: { $set: true },
        id: { $set: value },
        isLoading: { $set: false }
      } )

    case AuthActionTypes.INITIAL_CLIENT_FAILED:
      return update( state, {
        user: { $set: false },
        isLoading: { $set: false },
        error: { $set: action.error }
      } )

    case AuthActionTypes.PINCODE_FIELD_EMPTY:
      return update( state, {
        emailError: { $set: Errors.pincodeError }
      } )

    case AuthActionTypes.ERROR:
      return update( state, {
        error: { $set: !initialAuthState.error },
        emailError: { $set: Errors.newPasswordError }
      } );

    default:
      return state;
  }
}
