
export enum AuthActionTypes {
  TOGGLE_MODAL_STATE = 'TOGGLE_MODAL_STATE',
  DECREMENT_WINDOW_STATE = 'DECREMENT_WINDOW_STATE',
  INCREMENT_WINDOW_STATE = 'INCREMENT_WINDOW_STATE',
  INPUT_FIELD_EMPTY = 'INPUT_FIELD_EMPTY',
  ON_CHANGE_MAIL_INPUT = 'ON_CHANGE_MAIL_INPUT',
  ON_CHANGE_PASSWORD_INPUT = 'ON_CHANGE_PASSWORD_INPUT',
  ON_CHANGE_REPEAT_PASSWORD_INPUT = 'ON_CHANGE_REPEAT_PASSWORD_INPUT',
  ON_CHANGE_PINCODE_INPUT = 'ON_CHANGE_PINCODE_INPUT',
  TOGGLE_SIGN_UP_STATE = 'TOGGLE_SIGN_UP_STATE',
  SIGN_UP = 'SIGN_UP',
  SIGN_UP_SUCCEEDED = 'SIGN_UP_SUCCEEDED',
  SIGN_UP_FAILED = 'SIGN_UP_FAILED',
  CONFIRM_PINCODE = 'CONFIRM_PINCODE',
  CONFIRM_PINCODE_SUCCEEDED = 'CONFIRM_PINCODE_SUCCEEDED',
  CONFIRM_PINCODE_FAILED = 'CONFIRM_PINCODE_FAILED',
  SIGN_IN = 'SIGN_IN',
  SIGN_IN_SUCCEEDED = 'SIGN_IN_SUCCEEDED',
  SIGN_IN_FAILED = 'SIGN_IN_FAILED',
  SIGN_OUT = 'SIGN_OUT',
  SIGN_OUT_SUCCEEDED = 'SIGN_OUT_SUCCEEDED',
  SIGN_OUT_FAILED = 'SIGN_OUT_FAILED',
  FORGOT_PASSWORD_BUTTON = 'FORGOT_PASSWORD_BUTTON',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_SUCCEEDED = 'FORGOT_SUCCEEDED',
  FORGOT_FAILED = 'FORGOT_FAILED',
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCEEDED = 'RESET_PASSWORD_SUCCEEDED',
  RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED',
  INITIAL_CLIENT_REQUEST = 'INITIAL_CLIENT_REQUEST',
  INITIAL_CLIENT_SUCCEEDED = 'INITIAL_CLIENT_SUCCEEDED',
  INITIAL_CLIENT_FAILED = 'INITIAL_CLIENT_FAILED',
  PINCODE_FIELD_EMPTY = 'PINCODE_FIELD_EMPTY',
  ERROR = 'ERROR',
}

interface toggleModalState {
  type: AuthActionTypes.TOGGLE_MODAL_STATE;
}

interface toggleSignUpState {
  type: AuthActionTypes.TOGGLE_SIGN_UP_STATE;
}

interface incrementWindowState {
  type: AuthActionTypes.INCREMENT_WINDOW_STATE;
}

interface inputFieldEmpty {
  type: AuthActionTypes.INPUT_FIELD_EMPTY;
}

interface decrementWindowState {
  type: AuthActionTypes.DECREMENT_WINDOW_STATE;
}

interface onChangeMailInput {
  type: AuthActionTypes.ON_CHANGE_MAIL_INPUT;
  payload: string;
}

export interface OnChangePasswordInput {
  type: AuthActionTypes.ON_CHANGE_PASSWORD_INPUT;
  payload: string;
}

export interface OnChangeRepeatPasswordInput {
  type: AuthActionTypes.ON_CHANGE_REPEAT_PASSWORD_INPUT;
  payload: string;
}

interface onChangePinCodeInput {
  type: AuthActionTypes.ON_CHANGE_PINCODE_INPUT;
  payload: string;
}

export interface signUpType {
  type: AuthActionTypes.SIGN_UP;
  email: string;
  password: string;
}

interface User {
  id: string;
  username: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  roles?: string[];
  token?: string;
  refreshToken?: string;
}

interface signUpSucceeded {
  type: AuthActionTypes.SIGN_UP_SUCCEEDED;
  user: User;
}

interface signUpFailed {
  type: AuthActionTypes.SIGN_UP_FAILED;
  error: Error;
}

export interface confirmPinCodeType {
  type: AuthActionTypes.CONFIRM_PINCODE;
  email: string;
  pincode: string;
}

interface confirmPinCodeSucceeded {
  type: AuthActionTypes.CONFIRM_PINCODE_SUCCEEDED;
  user: string;
}

interface confirmPinCodeFailed {
  type: AuthActionTypes.CONFIRM_PINCODE_FAILED;
  error: Error;
}

export interface signInType {
  type: AuthActionTypes.SIGN_IN;
  email: string;
  password: string;
}

interface SignInResult {
  user: {
    id: string;
    username: string;
    email: string;
  };
  token: string;
  refreshToken?: string;
  roles?: string[];
}

interface signInSucceededType {
  type: AuthActionTypes.SIGN_IN_SUCCEEDED;
  result: SignInResult;
}

interface signInFailedType {
  type: AuthActionTypes.SIGN_IN_FAILED;
  error: Error;
}

interface ForgotPasswordButtonType {
  type: AuthActionTypes.FORGOT_PASSWORD_BUTTON
}

export interface ForgotPasswordType {
  type: AuthActionTypes.FORGOT_PASSWORD;
  email: string
}

export interface ResetPasswordRequestAction {
  type: AuthActionTypes.RESET_PASSWORD_REQUEST,
  email: string,
  pincode: string,
  password: string
}

interface ResetPasswordSucceeded {
  type: AuthActionTypes.RESET_PASSWORD_SUCCEEDED
}

interface ResetPasswordFailed {
  type: AuthActionTypes.RESET_PASSWORD_FAILED,
  error: Error
}


interface ForgotPasswordSucceededType {
  type: AuthActionTypes.FORGOT_SUCCEEDED
}

interface ForgotPasswordFailedType {
  type: AuthActionTypes.FORGOT_FAILED
  error: Error
}

interface InitialClientRequestType {
  type: AuthActionTypes.INITIAL_CLIENT_REQUEST
}

interface Session {
  userId: string;
  username: string;
  token: string;
  refreshToken?: string;
  expiresAt: string;
}

interface InitialClientSucceededType {
  type: AuthActionTypes.INITIAL_CLIENT_SUCCEEDED;
  session: Session;
}

interface InitialClientFailedType {
  type: AuthActionTypes.INITIAL_CLIENT_FAILED,
  error: Error
}

interface signOutType {
  type: AuthActionTypes.SIGN_OUT
}

interface signOutSucceededType {
  type: AuthActionTypes.SIGN_OUT_SUCCEEDED
}

interface signOutFailedType {
  type: AuthActionTypes.SIGN_OUT_FAILED
}

interface PincodeFieldEmptyType {
  type: AuthActionTypes.PINCODE_FIELD_EMPTY
}

interface Error {
  type: AuthActionTypes.ERROR;
}

export type AuthActions =
  | toggleModalState
  | toggleSignUpState
  | incrementWindowState
  | inputFieldEmpty
  | decrementWindowState
  | onChangeMailInput
  | OnChangePasswordInput
  | OnChangeRepeatPasswordInput
  | onChangePinCodeInput
  | signUpType
  | signUpSucceeded
  | signUpFailed
  | confirmPinCodeType
  | confirmPinCodeSucceeded
  | confirmPinCodeFailed
  | signInType
  | signInSucceededType
  | signInFailedType
  | ForgotPasswordButtonType
  | ForgotPasswordType
  | ForgotPasswordSucceededType
  | ForgotPasswordFailedType
  | ResetPasswordRequestAction
  | ResetPasswordSucceeded
  | ResetPasswordFailed
  | signOutType
  | signOutSucceededType
  | signOutFailedType
  | InitialClientRequestType
  | InitialClientSucceededType
  | InitialClientFailedType
  | PincodeFieldEmptyType
  | Error;

export const ToggleModalState = () => ( { type: AuthActionTypes.TOGGLE_MODAL_STATE } );
export const toggleSignUpState = () => ( { type: AuthActionTypes.TOGGLE_SIGN_UP_STATE } );

export const incrementWindowState = () => ( { type: AuthActionTypes.INCREMENT_WINDOW_STATE } );
export const decrementWindowState = () => ( { type: AuthActionTypes.DECREMENT_WINDOW_STATE } );

export const onChangeMailInput = ( email: string ) => ( {
  type: AuthActionTypes.ON_CHANGE_MAIL_INPUT,
  payload: email,
} );

export const onChangePasswordInput = ( password: string ) => ( {
  type: AuthActionTypes.ON_CHANGE_PASSWORD_INPUT,
  payload: password,
} );

export const onChangeRepeatPasswordInput = ( password: string ) => ( {
  type: AuthActionTypes.ON_CHANGE_REPEAT_PASSWORD_INPUT,
  payload: password,
} );

export const onChangePinCodeInput = ( pincode: string ) => ( {
  type: AuthActionTypes.ON_CHANGE_PINCODE_INPUT,
  payload: pincode,
} );

export const signUpEnter = ( email: string, password: string ) => ( {
  type: AuthActionTypes.SIGN_UP,
  email,
  password,
} );

export const signUpSucceeded = ( result ) => ( {
  type: AuthActionTypes.SIGN_UP_SUCCEEDED,
  result,
} );

export const signUpFailed = ( error ) => ( {
  type: AuthActionTypes.SIGN_UP_FAILED,
  error,
} );

export const confirmPinCode = ( email: string, pincode: string ) => ( {
  type: AuthActionTypes.CONFIRM_PINCODE,
  email,
  pincode,
} );

export const confirmPinCodeSucceeded = ( user ) => ( {
  type: AuthActionTypes.CONFIRM_PINCODE_SUCCEEDED,
  user,
} );

export const confirmPinCodeFailed = ( error ) => ( {
  type: AuthActionTypes.CONFIRM_PINCODE_FAILED,
  error,
} );

export const signInEnter = ( email: string, password: string ) => ( {
  type: AuthActionTypes.SIGN_IN,
  email,
  password,
} );

export const signInSucceeded = ( result ) => ( {
  type: AuthActionTypes.SIGN_IN_SUCCEEDED,
  result,
} );

export const signInFailed = ( error: Error ) => ( {
  type: AuthActionTypes.SIGN_IN_FAILED,
  error,
} );

export const forgotPasswordButton = () => ( {
  type: AuthActionTypes.FORGOT_PASSWORD_BUTTON
} )

export const forgotPassword = ( email: string ) => ( {
  type: AuthActionTypes.FORGOT_PASSWORD,
  email
} )

export const forgotPasswordSucceeded = ( res ) => ( {
  type: AuthActionTypes.FORGOT_SUCCEEDED,
  res,
} )

export const forgotPasswordFailed = ( error: Error ) => ( {
  type: AuthActionTypes.FORGOT_FAILED,
  error
} )

export const resetPasswordRequest = ( email: string, pincode: string, password: string ) => ( {
  type: AuthActionTypes.RESET_PASSWORD_REQUEST,
  email,
  pincode,
  password
} )

export const resetPasswordSucceeded = (result) => ( {
  type: AuthActionTypes.RESET_PASSWORD_SUCCEEDED,
  result
} )


export const resetPasswordFailed = ( error: Error ) => ( {
  type: AuthActionTypes.RESET_PASSWORD_FAILED,
  error
} )



export const initialClient = () => ( {
  type: AuthActionTypes.INITIAL_CLIENT_REQUEST
} )

export const initialCliendSucceeded = ( session ) => ( {
  type: AuthActionTypes.INITIAL_CLIENT_SUCCEEDED,
  session
} )

export const initialCliendFailed = ( error: Error ): InitialClientFailedType => ( {
  type: AuthActionTypes.INITIAL_CLIENT_FAILED,
  error
} )

export const signOutEnter = () => ( {
  type: AuthActionTypes.SIGN_OUT
} )

export const signOutSucceeded = () => ( {
  type: AuthActionTypes.SIGN_OUT_SUCCEEDED
} )

export const signOutFailed = () => ( {
  type: AuthActionTypes.SIGN_OUT_FAILED
} )

export const pincodeFieldEmpty = () => ( {
  type: AuthActionTypes.PINCODE_FIELD_EMPTY
} )



export const errorAuth = () => ( { type: AuthActionTypes.ERROR } );
