/*External dependencies*/
import { useTranslation } from "react-i18next";

export const Errors = {
  emailIncorrect: 'Почта туура эмес корсотулгон',
  emailFieldEmpty: 'Email field empty',
  pincodeIncorrect: 'Пинкод неправилный',
  hasAnAccount: 'Мындай аккаунт регистрацияланган',
  signInError: 'Почта же пароль туура эмес!',
  resetPasswordError: 'Что-то пошло не так',
  forgotError: 'Почта регистрация боло элек',
  pincodeError: 'Пинкод туура эмес',
  newPasswordError:'Пароли не совпадають'
};

// export function getErrors() {

//   return {
//     emailIncorrect: t("email_incorrectError"),
//     emailFieldEmpty: t("email_field_emptyError"),
//     pincodeIncorrect: t("pincode_incorrectError"),
//     hasAnAccount: t("hasAnAccountError"),
//     signInError: t("signInError"),
//     resetPasswordError: t("resetPasswordError"),
//     forgotError: t("forgotError"),
//     newPasswordError: t("new_password_error"),
//   };
// }

