/* External dependencies */
import { applyMiddleware, combineReducers, createStore as createReduxStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

/* Local dependencies */
import { AuthReducer } from '../components/webApp/auth/redux/reducer';
import { signUpEpic, signUpConfirmPincode, signInEpic, signOutEpic, initialUserEpic, forgotPasswordEpic, confirmForgotPasswordEpic } from '../components/webApp/auth/redux/epics';

const rootEpic = combineEpics(
  signUpEpic,
  signUpConfirmPincode,
  signInEpic,
  signOutEpic,
  initialUserEpic,
  forgotPasswordEpic,
  confirmForgotPasswordEpic
);

const rootReducer = combineReducers( {
  AuthReducer,
} );

let store;

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore( rootReducer, composeWithDevTools( applyMiddleware( epicMiddleware ) ) );

  epicMiddleware.run( rootEpic );

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
