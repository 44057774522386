/*External dependencies*/
import CognitoClient from '@mancho.devs/cognito';
import { Observable, catchError, from, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';
import { initialCliendFailed, initialCliendSucceeded, signInType } from './action';

/*Local dependencies*/
import {
  AuthActionTypes,
  AuthActions,
  confirmPinCodeFailed,
  confirmPinCodeSucceeded,
  confirmPinCodeType,
  signInFailed,
  signInSucceeded,
  signOutFailed,
  signOutSucceeded,
  signUpFailed,
  signUpSucceeded,
  signUpType,
  forgotPasswordSucceeded,
  forgotPasswordFailed,
  ForgotPasswordType,
  ResetPasswordRequestAction,
  resetPasswordSucceeded,
  resetPasswordFailed
} from './action';
import { getSession } from '../../../../client/graphql';

const cognitoClient = new CognitoClient( {
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
} );

export function signUpEpic( action$ ): Observable<AuthActions> {
  return action$.pipe(
    filter( ( action: AuthActions ) => action.type === AuthActionTypes.SIGN_UP ),
    mergeMap( ( { email, password }: signUpType ) =>
      cognitoClient.signUp( email, password ).then( signUpSucceeded ).catch( signUpFailed ),
    ),
  );
}

export function signUpConfirmPincode( action$ ): Observable<AuthActions> {
  return action$.pipe(
    filter( ( action: AuthActions ) => action.type === AuthActionTypes.CONFIRM_PINCODE ),
    mergeMap( ( { email, pincode }: confirmPinCodeType ) =>
      cognitoClient.signUpConfirmCode( email, pincode ).then( confirmPinCodeSucceeded ).catch( confirmPinCodeFailed ),
    ),
  );
}

export function signInEpic( action$ ): Observable<AuthActions> {
  return action$.pipe(
    filter( ( action: AuthActions ) => action.type === AuthActionTypes.SIGN_IN ),
    mergeMap( ( action: signInType ) =>
      from( cognitoClient.signIn( action.email, action.password ) ).pipe(
        mergeMap( ( response ) =>
          from( getSession() ).pipe(
            mergeMap( ( session ) => {
              return from( [
                signInSucceeded( response ),
                initialCliendSucceeded( session )
              ] )
            } ),
            catchError( ( error ) => {
              return of( signInSucceeded, initialCliendFailed( error ) )
            } )
          ) ),
        catchError( ( error ) => of( signInFailed( error ) ) ),
      ),
    ),
  );
}

export function forgotPasswordEpic( action$ ): Observable<AuthActions> {
  return action$.pipe(
    filter( ( action: AuthActions ) => action.type === AuthActionTypes.FORGOT_PASSWORD ),
    switchMap( ( { email }:ForgotPasswordType ) =>
      cognitoClient.forgotPassword( email ).then( forgotPasswordSucceeded ).catch( forgotPasswordFailed )
    )
  )
}

export function confirmForgotPasswordEpic( action$ ): Observable<AuthActions> {
  return action$.pipe(
    filter( ( action: AuthActions ) => action.type === AuthActionTypes.RESET_PASSWORD_REQUEST ),
    switchMap( ( { email, pincode, password }: ResetPasswordRequestAction ) =>
      cognitoClient.confirmPassword( email, pincode, password ).then( resetPasswordSucceeded ).catch( resetPasswordFailed ),
    ),
  );
}

export function signOutEpic( action$ ): Observable<AuthActions> {
  return action$.pipe(
    filter( ( action: AuthActions ) => action.type === AuthActionTypes.SIGN_OUT ),
    switchMap( () =>
      from( cognitoClient.signOut() ).pipe(
        mergeMap( () => of( signOutSucceeded() ) ),
        catchError( () => of( signOutFailed() ) ),
      ),
    ),
  );
}

export function initialUserEpic( action$ ): Observable<AuthActions> {
  return action$.pipe(
    filter( ( action: AuthActions ) => action.type === AuthActionTypes.INITIAL_CLIENT_REQUEST ),
    switchMap( ( { } ) => getSession().then( initialCliendSucceeded ).catch( initialCliendFailed ) ),
  );
}
